export default [
  {
    id: 1,
    title: 'Bike',
    price: 50,
    category: 'Sports',
  },
  {
    id: 2,
    title: 'Tennis Racquet',
    price: 25,
    category: 'Sports',
  },
  {
    id: 3,
    title: 'Football',
    price: 30,
    category: 'Sports',
  },
];